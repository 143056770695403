<template>
  <div class="join">
    <beacon-banner
      :bannerSrc="banner_3"
      title="欢迎加入我们  为梦想而奋斗"
      subTitle="Welcome to join us , Strive for the dream"
    ></beacon-banner>
    <div class="content">
      <div class="section" v-for="(section, index) in sectionList" :key="index">
        <div
          class="section_item"
          v-for="(sectionItem, index) in section"
          :key="index"
        >
          <div class="title p-0">{{ sectionItem.title }}</div>
          <div class="sub_title">岗位职责</div>
          <ul class="duty">
            <li
              class="duty_item"
              v-for="(duty, index) in sectionItem.dutyList"
              :key="index"
            >
              {{ duty }}
            </li>
          </ul>
          <div class="sub_title">任职资格</div>
          <ul class="duty">
            <li
              class="duty_item"
              v-for="(duty, index) in sectionItem.dutyList2"
              :key="index"
            >
              {{ duty }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { banner_3 } from "@/utils/images";
export default {
  data() {
    return {
      banner_3,
      // 全部职位列表
      sectionList: [
        [
          {
            type: 1, // type表示职位类别
            title: "销售代表（5-6K起）",
            dutyList: [
              "1、负责所属区域的产品推广和销售，完成销售的任务指标；",
              "2、制定销售计划，并按计划开发新客户；",
              "3、做好销售合同的签订管理等相关工作，以及协调处理市场问题；",
              "4、协助销售总监制定销售策略、销售计划，销售目标，完成目标；",
              "5、保持良好的职业规范的行为，与客户建立良好关系，维护企业形象。",
            ],
            dutyList2: [
              "1、有一年以上工作经验，有餐饮行业从事经验者优先；",
              "2、抗压能力好，有激情",
            ],
          },
          {
            type: 1, // type表示职位类别
            title: "大客户经理（8-10K起）",
            dutyList: [
              "1、负责收集客户信息，持续跟进意向客户；",
              "2、根据市场状况及时调整公司市场运营战略，制定合理的市场运营方案；",
              "3、善于把握客户的促成技巧，合理运用各类销售技能促成订单达成",
            ],
            dutyList2: [
              "1、大专以上学历，市场营销专业优先",
              "2、三年以上销售经验，有过餐饮行业、软件推广从事经验者优先考虑。",
            ],
          },
        ],
        [
          {
            type: 2, // type表示职位类别
            title: ".Net开发工程师（6-9K起）",
            dutyList: [
              "1、基于winform技术的界面及逻辑实现，编写自定义控件等；",
              "2、根据设计文档或需求说明完成项目模块代码编写、调试和维护等；",
              "3、分析并解决软件开发过程中 的一些问题；",
              "4、完成MySql数据库各种类型脚本的编写工作；",
              "5、解决技术支持实施项目的过程中遇到的相关技术问题；",
              "6、具备基本的编程素养及代码规范等；",
            ],
            dutyList2: [
              "1、精通winform开发，熟练掌握自定义控件编写等；",
              "2、至少精通MySql、SqlServer一种脚本开发语言；",
              "3、有较强的程序编写能力以及良好的代码注释习惯，要求结构清晰，命名规范，逻辑性强，代码冗余率低；",
              "4、纯属面向对象的编程思想，要求精通编程调试和相关技术；",
              "5、有过.Net与硬件交互(例如实现打印机规定格式的打印等)以及相关餐饮业经验的优先；",
              "6、纯属.Net平台上的开发，具备3年以上的相关工作经验的优先；",
            ],
          },
          {
            type: 2, // type表示职位类别
            title: "技术实施工程师（6-9K起）",
            dutyList: [
              "1、负责客户软件系统的日常维护、定期检查、日常技术支持。",
              "2、负责产品的售前支持、产品演示、部署方案编写；",
              "3、负责向客户提供专业技术咨询与培训服务。",
              "4、负责客户软件系统的更新升级。",
              "5、负责客户系统软件说明书等手册的更新。",
              "6、根据客户的实际要求提供相应的技术支持服务工作。",
              "7、负责客户软件安装和培训。",
            ],
            dutyList2: [
              "1、大专以上学历，计算机应用、酒店管理、财务会计等专业；",
              "2、有1年以上工作经验；（欢迎优秀毕业生应聘）",
              "3、能适应省内出差。",
              "4、有激情，实干努力认真。",
            ],
          },
        ],
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.section {
  overflow: hidden;
  .section_item {
    width: 50%;
    float: left;
    margin-top: 5.8%;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e1e1e1;
      margin-top: 6.66%;
    }
    &:nth-child(2n + 1) {
      padding-right: 2.94%;
    }
    &:nth-child(2n) {
      padding-left: 2.94%;
    }

    .title {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 1.5;
      justify-content: flex-start;
    }
    .sub_title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #666666;
      margin: 7% 0;
      margin-bottom: 4%;
    }
    .duty {
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 47px;
      color: #666666;
      .duty_item {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .section {
    .section_item {
      margin-top: 30vpx;
      width: 100%;
      padding: 0 !important;
      &::after {
        display: none;
      }
      .title {
        font-size: 20vpx;
        font-weight: 400;
        line-height: normal;
        &::before {
          display: none;
        }
      }
      .sub_title {
        font-size: 16vpx;
        margin-top: 20vpx;
        margin-bottom: 15vpx;
      }
      .duty {
        font-size: 14vpx;
        line-height: 24vpx;
        .duty_item {
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>